import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  effect,
  HostBinding,
  input,
  type TemplateRef,
} from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import type { IconName } from '../icon/icon.models';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { ButtonStyleDirective } from './button-style.directive';
import {
  DEFAULT_BUTTON_BACKGROUND_COLOR,
  DEFAULT_BUTTON_HEIGHT,
  DEFAULT_BUTTON_SIZE,
  DEFAULT_BUTTON_TYPE,
  DEFAULT_BUTTON_VARIANT,
  type ButtonBackgroundColor,
  type ButtonSize,
  type ButtonType,
  type ButtonVariant,
} from './button.models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonStyleDirective,
    IconComponent,
    LoadingSpinnerComponent,
    NgClass,
    NgTemplateOutlet,
  ],
  selector: 'app-button',
  styles: `
    :host {
      @apply tw-inline-block;
    }
  `,
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  readonly ariaLabel = input<string>();
  readonly backgroundColor = input(DEFAULT_BUTTON_BACKGROUND_COLOR, {
    transform: (value: ButtonBackgroundColor | undefined) =>
      value ?? DEFAULT_BUTTON_BACKGROUND_COLOR,
  });
  readonly buttonCssClasses = input<NgClass['ngClass']>(undefined);
  readonly buttonLabelCssClasses = input<NgClass['ngClass']>(undefined);
  readonly iconLeft = input<IconName | undefined>(undefined);
  readonly iconRight = input<IconName | undefined>(undefined);
  readonly isDisabled = input(false, {
    transform: (value: boolean | undefined) => value ?? false,
  });
  readonly isMultiLineButton = input<boolean>(false);
  readonly shouldSetTextColor = input(true, {
    transform: (value: boolean | undefined) => value ?? true,
  });
  readonly shouldShowLoadingIndicator = input(false, {
    transform: (value: boolean | undefined) => value ?? false,
  });
  readonly size = input(DEFAULT_BUTTON_SIZE, {
    transform: (value: ButtonSize | undefined) => value ?? DEFAULT_BUTTON_SIZE,
  });
  readonly type = input(DEFAULT_BUTTON_TYPE, {
    transform: (value: ButtonType | undefined) => value ?? DEFAULT_BUTTON_TYPE,
  });
  readonly variant = input(DEFAULT_BUTTON_VARIANT, {
    transform: (value: ButtonVariant | undefined) =>
      value ?? DEFAULT_BUTTON_VARIANT,
  });

  readonly iconLeftTemplate = contentChild<TemplateRef<void>>('iconLeft');
  readonly iconRightTemplate = contentChild<TemplateRef<void>>('iconRight');

  /**
   * NOTE:
   * Please stop using this input. Apply the desired width directly to the component
   * tag if needed. Please note that setting the width manually is no longer necessary
   * to prevent layout shifts when displaying a loader. The current button implementation
   * doesn't change the button width when showing or hiding the loader indicator.
   *
   * In most cases, it's best to skip setting the width manually, as the button
   * will automatically adjust its width to the displayed content.
   *
   * @deprecated
   */
  readonly width = input<string>();

  /**
   * NOTE:
   * Please stop using this input. Apply the desired height directly to the component
   * tag if needed.
   *
   * @deprecated
   */
  readonly height = input<string>();

  /**
   * NOTE:
   * Below host bindings will be removed once we stop using {@link width} and {@link height} inputs
   */
  @HostBinding('style.width') hostWidth?: string;
  @HostBinding('style.height') hostHeight?: string;

  constructor() {
    effect(() => {
      this.hostWidth = this.width() ?? 'min-content';
      this.hostHeight =
        this.height() ??
        (this.isMultiLineButton() ? 'min-content' : DEFAULT_BUTTON_HEIGHT);
    });
  }

  onClick(event_: MouseEvent): void {
    if (!this.isDisabled()) {
      return;
    }

    event_.stopPropagation();
  }
}
