import { DialogModule } from '@angular/cdk/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  importProvidersFrom,
  provideExperimentalCheckNoChangesForDebug,
  provideZoneChangeDetection,
  type ApplicationConfig,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NoPreloading,
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import {
  provideTransloco,
  TranslocoService,
  type Translation,
} from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxPermissionsModule } from 'ngx-permissions';
import type { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { ROUTES } from './core/core.routes';
import { CheckAuthorizationStatusInterceptor } from './core/interceptors/check-authorization-status.interceptor';
import { SnackbarService } from './core/services/snackbar.service';
import { TranslocoHttpLoader } from './core/services/transloco-http-loader.service';
import { effects, metaReducers, reducers } from './core/store';
import { AVAILABLE_LANGUAGES } from './shared/consts/available-languages.const';

// TODO: implement an interceptor to abort GET requests when the user navigates away from the page

const configuration = {
  defaultApplicationLanguage: 'en',
  router: {
    shouldEnableDebugTracing: false,
    shouldEnableViewTransitions: false,
  },
  zoneless: {
    shouldPerformChecksForCompatibility: {
      // NOTE: we should enable this from time to time to check for compatibility issues
      periodically: false,
      whenStable: true,
    },
  },
};

function preloadTranslationFactory(
  transloco: TranslocoService,
): () => Observable<Translation> {
  return () => {
    transloco.setActiveLang(configuration.defaultApplicationLanguage);
    return transloco.load(configuration.defaultApplicationLanguage);
  };
}

export const applicationConfiguration: ApplicationConfig = {
  providers: [
    provideRouter(
      ROUTES,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      ...(configuration.router.shouldEnableDebugTracing ?
        [withDebugTracing()]
      : []),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withPreloading(NoPreloading),
      ...(configuration.router.shouldEnableViewTransitions ?
        [withViewTransitions()]
      : []),
      withComponentInputBinding(),
    ),
    provideZoneChangeDetection({
      eventCoalescing: true,
      runCoalescing: true,
    }),
    ...((
      configuration.zoneless.shouldPerformChecksForCompatibility.whenStable ||
      configuration.zoneless.shouldPerformChecksForCompatibility.periodically
    ) ?
      [
        provideExperimentalCheckNoChangesForDebug({
          exhaustive: true,
          interval:
            (
              configuration.zoneless.shouldPerformChecksForCompatibility
                .periodically
            ) ?
              1000
            : undefined,
          useNgZoneOnStable:
            configuration.zoneless.shouldPerformChecksForCompatibility
              .whenStable,
        }),
      ]
    : []),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      CoreModule,
      AuthModule,
      MatMenuModule,
      LayoutModule,
      NgxPermissionsModule.forRoot(),
      SnackbarService,
      DialogModule,
      StoreRouterConnectingModule.forRoot({
        navigationActionTiming: NavigationActionTiming.PostActivation,
      }),
      StoreModule.forRoot(reducers, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }),
      EffectsModule.forRoot(effects),
      environment.production ?
        []
      : StoreDevtoolsModule.instrument({
          autoPause: true,
          maxAge: 25,
        }),
    ),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideTransloco({
      config: {
        availableLangs: AVAILABLE_LANGUAGES,
        defaultLang: configuration.defaultApplicationLanguage,
        fallbackLang: configuration.defaultApplicationLanguage,
        missingHandler: {
          // NOTE: it will use the first language set in the `fallbackLang` property
          useFallbackTranslation: environment.production,
        },
        // NOTE: enable this option if your application supports changing language in runtime
        reRenderOnLangChange: false,
        prodMode: environment.production,
        flatten: {
          aot: environment.production,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: preloadTranslationFactory,
      deps: [TranslocoService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CheckAuthorizationStatusInterceptor,
      multi: true,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production && !environment.isInCypress,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
