<button
  class="tw-relative tw-inline-block tw-size-full"
  [attr.aria-label]="ariaLabel()"
  [buttonBackgroundColor]="backgroundColor()"
  [buttonSize]="size()"
  [buttonVariant]="variant()"
  [disabled]="isDisabled()"
  [ngClass]="buttonCssClasses()"
  [type]="type()"
  (click)="onClick($event)"
  appButtonStyle
>
  <div
    class="tw-flex tw-size-full tw-items-center tw-gap-2"
    [ngClass]="{ 'tw-invisible': shouldShowLoadingIndicator() }"
  >
    @if (iconLeftTemplate() || iconLeft()) {
      <div class="tw-whitespace-nowrap" data-testid="buttonLeftIcon">
        <ng-container
          *ngTemplateOutlet="
            iconLeftTemplate() ?? iconTemplate;
            context: { $implicit: iconLeft() }
          "
        />
      </div>
    }

    <div
      class="tw-flex-1 tw-flex tw-items-center tw-justify-center {{
        isMultiLineButton() ? '' : 'tw-truncate'
      }}"
      [ngClass]="buttonLabelCssClasses()"
      data-testid="buttonLabel"
    >
      <ng-content />
    </div>

    @if (iconRightTemplate() || iconRight()) {
      <div class="tw-whitespace-nowrap" data-testid="buttonRightIcon">
        <ng-container
          *ngTemplateOutlet="
            iconRightTemplate() ?? iconTemplate;
            context: { $implicit: iconRight() }
          "
        />
      </div>
    }
  </div>

  @if (shouldShowLoadingIndicator()) {
    <app-loading-spinner
      class="tw-absolute tw-inset-0 tw-flex tw-size-full tw-items-center tw-justify-center"
      [color]="'currentColor'"
      [diameter]="18"
      data-testid="button-loading-spinner"
    />
  }
</button>

<ng-template #iconTemplate let-icon>
  <app-icon [color]="'currentcolor'" [customSizePx]="12" [icon]="icon" />
</ng-template>
